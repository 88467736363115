<template>
  <MainLayout>
    <template v-slot:default>
      <!-- popup подтверждающий удаление -->
      <DeletePopupAdmin
        :title="title"
        :name="fullName"
        :dialog="dialog"
        @closeDeletePopup="closeDeletePopup"
        :delete-function="deleteStaff"
      />
      <!-- /popup подтверждающий удаление-->
      <TableFilters :queries="queries">
        <template v-slot:filter-title
          ><span class="title-filter">Всего:</span>{{ sourceObject.count }}
          <!--          <span>сотрудников</span>-->
        </template>

        <template v-slot:add-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4 "
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
          <router-link to="createStaff" class="btn btn-add btn_color_green">
            Добавить сотрудника
          </router-link>
        </template>
      </TableFilters>
      <TableLayout
        disable-router
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
        delete-button-direction-start
      >
        <template slot-scope="{ itemFather }">
          <button
            @click="clickDelete(itemFather)"
            class="form__btn-delete btn btn-delete"
            style="background-color: #eb5c6d"
          ></button>
        </template>
      </TableLayout>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import DeletePopupAdmin from "@/components/popup/DeletePopupCommon";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import tableColsStaff from "@/helpers/tableCols/staff";

export default {
  name: "StaffPage",
  data() {
    return {
      loadingBtn: false,
      dialog: false,
      idStaff: "", // id удаленного персонала
      roleStaff: null, //роль удаленного персонала
      fullName: "",

      queries: query.staff
      // type: "default",
    };
  },
  components: { TableFilters, TableLayout, MainLayout, DeletePopupAdmin },
  computed: {
    title() {
      return this.roleStaff == 50 ? "администратора" : "техника";
    },
    header() {
      return tableColsStaff;
    },
    sourceObject() {
      return this.$store.getters.getServiceOrgStaffPageList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query,
        "organization-personal": this.$store.getters.getCurrentOrganization
      }
    });
    this.isDateLoaded = true;
    this.$store
      .dispatch("fetchServiceOrgStaffPageList", {
        organization_pk: this.$store.getters.getCurrentOrganization
      })
      .finally(() => {
        this.isDateLoaded = false;
      });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query,
          "organization-personal": this.$store.getters.getCurrentOrganization
        }
      });
      this.isDateLoaded = true;
      this.$store
        .dispatch("fetchServiceOrgStaffPageList", {
          organization_pk: this.$store.getters.getCurrentOrganization
        })
        .finally(() => {
          this.isDateLoaded = false;
        });
    }
    next();
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadServiceOrgStaffPageList", {
          organization_pk: this.$store.getters.getCurrentOrganization,
          header: this.header
        })
        .then(() => {
          this.loadingBtn = false;
        });
    },

    clickDelete(obj) {
      this.fullName = obj.full_name;
      this.idStaff = obj.id;
      this.roleStaff = obj.position;
      this.dialog = true;
    },
    closeDeletePopup(data) {
      this.dialog = data;
    },

    deleteStaff() {
      let self = this;
      this.isDateLoaded = true;
      this.$store
        .dispatch("deleteStaffFromGroup", {
          id: this.idStaff,
          organizationId: this.$store.getters.getCurrentOrganization,
          role: self.roleStaff
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.personnel)
          );
        })
        .finally(() => {
          this.$store
            .dispatch("fetchServiceOrgStaffPageList", {
              organization_pk: this.$store.getters.getCurrentOrganization
            })
            .finally(() => {
              this.isDateLoaded = false;
            });
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearUsersPage").then(() => next());
  }
};
</script>

<style scoped></style>
