import phoneFilter from "@/helpers/filters/phoneFilter";

const tableColsStaff = [
  {
    text: "ФИО",
    value: "last_name",
    renderFunction: value => value.full_name,
    sort: true
  },
  {
    text: "Роль",
    value: "",
    renderFunction: value =>
      `<div style="color: ${value.position == 50 ? "#95C23D" : " #000"}">${
        value.position == 50 ? "Администратор" : "Техник"
      }</div>`
  },
  {
    text: "Телефон",
    renderFunction: value => phoneFilter.execute(value?.phone_number),
    sort: true
  },
  {
    text: "Группы объектов",
    // value: ""
    renderFunction: value =>
      value.object_service_groups && value.object_service_groups.length > 0
        ? value.object_service_groups.reduce((arr, servG) => {
            arr += servG + "<br/>";
            return arr;
          })
        : "-",
    src: "/img/table_icons/tabbar__icon_service-group.svg",
    width: 20,
    sort: true
  },
  {
    text: "Объектов",
    value: "objects_count",
    src: "/img/table_icons/tabbar__icon_3.svg",
    width: 20,
    sort: true
  },
  {
    text: "Контроллеров",
    value: "controllers_count",
    src: "/img/table_icons/tabbar__icon_5.svg",
    width: 20,
    sort: true
  }
];

export default tableColsStaff;
